import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//utils
import {
  create,
  getAll,
  Delete,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { validate } from "./validations";

//ckeditor
import { SwalError } from "../../../../utils/constants";

//datepicker
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//middleware
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Axios from "axios";
import { ROUTES } from "../../../../utils/routes";
import { ArticleForm } from "../../../../components/PublicationForm/Article";
import { ChapterForm } from "../../../../components/PublicationForm/Chapter";
import { BookForm } from "../../../../components/PublicationForm/Book";
import { publication } from "../../../../utils/publicationType";
import { findIfSelectedByGroup } from "./utils";

registerLocale("es", es);

export const ManagePublications = () => {
  let { investigatorId, isAdmin } = useSelector(({ user }) => user);

  const { PUBLICATIONS, INVESTIGATORS, investigatorPUBLICATION, groupPUBLICATION } =
    paths;
  const { id } = useParams();
  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);
  const [isIpUser, setIsIpUser] = useState(false);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [date, setDate] = useState(new Date());
  const [investigators, setInvestigators] = useState([]);
  const [authors, setAuthors] = useState("");
  const [authorsId, setAuthorsId] = useState([]);
  const [journal, setJournal] = useState("");
  const [volPag, setVolPag] = useState("");
  const [publicationType, setPublicationType] = useState("");
  const [titleChapter, setTitleChapter] = useState("");
  const [coordinators, setCoordinators] = useState("");
  const [editors, setEditors] = useState("");
  const [directors, setDirectors] = useState("");
  const [isbn, setIsbn] = useState("");
  const [location, setLocation] = useState("");
  const [investId, setInvestId] = useState();
  const [investigatorPublications, setInvestigatorPublications] = useState([]);

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    loadDefaultData();
    if (pathname.includes("crear")) {
      setIsCreate(true);
      setPublicationType(publication.article);
    } else {
      loadEditData();
    }

    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);

  const loadDefaultData = async () => {
    try {
      let investigators = await getAll(INVESTIGATORS, source);
      if (isMounted.current) {
        setTimeout(() => filterAdmins(investigators), 550);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const filterAdmins = (investigators) => {
    let newinvestigators = investigators.filter(
      (u) =>
        u.email !== "admin@admin.com" && u.email !== "sombradoble@sombradoble.es"
    );
    setInvestigators(newinvestigators);
  };

  const loadEditData = async () => {
    try {
      const {
        title,
        url,
        date,
        authors,
        investigatorPublications,
        journal,
        volPag,
        investigator,
        publicationType,
        titleChapter,
        coordinators,
        editors,
        directors,
        isbn,
        location,
      } = await getById(PUBLICATIONS, id, source);

      setTitle(title);
      setUrl(url);
      setDate(new Date(date));
      setAuthors(authors);
      setJournal(journal);
      setVolPag(volPag);
      setInvestId(investigator.id);
      setPublicationType(publicationType);
      setTitleChapter(titleChapter);
      setCoordinators(coordinators);
      setEditors(editors);
      setDirectors(directors);
      setIsbn(isbn);
      setLocation(location === null ? "" : location);
      setInvestigatorPublications(investigatorPublications);

      investigator.id === investigatorId && setIsIpUser(true);

      setTimeout(() => filterAuthorsToEdit(investigatorPublications), 350);
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  // -----------------------

  const filterAuthorsToEdit = (investigatorPublications) => {
    let authors = [];
    investigatorPublications.map(({ investigator }) => authors.push(investigator));

    setAuthorsId(authors);
    setTimeout(() => newInvestigators(authors), 200);
  };

  const newInvestigators = (authors) => {
    // eslint-disable-next-line array-callback-return
    authors.map((auth) => {
      setInvestigators((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== auth?.id))
      );
    });
  };

  // -----------------------

  // HANDLE INVESTIGATORS
  const addAuthor = (ip) => {
    ip = JSON.parse(ip);

    //add chosenAuthor
    setAuthorsId((state) =>
      produce(state, (drafState) => {
        drafState.push(ip);
      })
    );

    //remove author from list
    setInvestigators((state) =>
      produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
    );
  };

  const removeAuthor = (ip, investigator) => {
    if (isIpUser || isCreate || isAdmin) {
      // remove chosenIp
      setAuthorsId((state) =>
        produce(state, (drafState) => drafState.filter(({ id }) => id !== ip?.id))
      );
      // add ip to list
      setInvestigators((state) =>
        produce(state, (drafState) => {
          drafState.push(ip);
        })
      );
    }
  };

  //----------------
  const handleSubmit = () => {
    const publication = build();
    buildInvestigatorGroupPublication(25);
    let notValid = validate(publication, authorsId);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} publicación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(publication);
    });
  };

  const handleConfirmation = (publication) => {
    let createOrUpdate = isCreate
      ? create(PUBLICATIONS, publication, source)
      : update(PUBLICATIONS, id, publication, source);
    createOrUpdate
      .then(async ({ id: publicationId }) => {
        const investigatorPublication = buildInvestigatorPublication(publicationId);

        const investigationGroupPublication =
          buildInvestigatorGroupPublication(publicationId);

        await create(
          `${investigatorPUBLICATION}/${publicationId}`,
          investigatorPublication,
          source
        );

        await create(
          `${groupPUBLICATION}/${publicationId}`,
          investigationGroupPublication,
          source
        );

        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Publicación ${createOrUpdateText} correctamente.`,
        });
        history.push(`${ROUTES.Publications.all}/${publicationId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const buildInvestigatorPublication = (publicationId) => {
    let investigatorIds = [];
    authorsId.forEach(({ id }) => {
      let selected = false;
      //checks if publication was previously selected by author
      let foundPublication = investigatorPublications.filter(
        ({ investigator, selected }) => investigator.id === id && selected === true
      );
      if (!!foundPublication.length) selected = true;

      investigatorIds.push({ investigatorId: id, publicationId, selected });
    });
    return investigatorIds;
  };

  const buildInvestigatorGroupPublication = (publicationId) => {
    let investigatorGroupIds = [];
    let selected = false;
    authorsId.forEach(({ investigationGroup }) => {
      if (investigationGroup) {
        selected = findIfSelectedByGroup(investigationGroup, id);
        investigatorGroupIds.push({
          investigationGroupId: investigationGroup.id,
          publicationId,
          selected,
        });
      }
    });
    const sortedinvestigatorGroupIds = investigatorGroupIds.filter(
      (tag, index, array) =>
        array.findIndex(
          (t) => t.investigationGroupId === tag.investigationGroupId
        ) === index
    );

    return sortedinvestigatorGroupIds;
  };

  const build = () => ({
    title,
    date: moment(date).format("YYYY-MM-DD"),
    journal,
    url,
    authors,
    volPag,
    investigatorId,
    publicationType,
    titleChapter,
    coordinators,
    editors,
    directors,
    isbn,
    location,
  });

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Publicaciones
        </button>
      );
    }
    // if is update && logged user is the owner
    if (!isCreate && (investId === investigatorId || isAdmin)) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Publicación
          </button>
          <button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres borrar esta publicación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        Delete(PUBLICATIONS, id, source)
          .then(({ id }) => {
            Swal.fire({
              icon: "success",
              text: `Publicación borrada correctamente.`,
            });
            history.push(ROUTES.Publications.all);
            setIsCreate(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo más tarde.",
            });
          });
    });
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Publicaciones</h3>
          </div>
          <div>{editDeleteButtons()}</div>
        </div>
      </div>
      <div className="container-fluid mt-2">
        <div className="form-row mt-4">
          <small>Selecciona el tipo de publicación</small>
          <select
            disabled={!isCreate}
            className="form-control input"
            value={publicationType}
            onChange={({ target: { value } }) => setPublicationType(value)}
          >
            <option value={publication.article}>Artículo científico</option>
            <option value={publication.chapter}>Capítulo de libro</option>
            <option value={publication.book}>Libro</option>
          </select>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">
          <span>{message}</span>
        </div>
      </div>
      <div className="container-fluid mt-2">
        {publicationType === publication.article && (
          <ArticleForm
            isIpUser={isIpUser}
            isCreate={isCreate}
            isAdmin={isAdmin}
            title={title}
            setTitle={setTitle}
            url={url}
            setUrl={setUrl}
            date={date}
            setDate={setDate}
            authors={authors}
            setAuthors={setAuthors}
            addAuthor={addAuthor}
            investigators={investigators}
            journal={journal}
            setJournal={setJournal}
            authorsId={authorsId}
            removeAuthor={removeAuthor}
            volPag={volPag}
            setVolPag={setVolPag}
          />
        )}
        {publicationType === publication.chapter && (
          <ChapterForm
            isIpUser={isIpUser}
            isCreate={isCreate}
            isAdmin={isAdmin}
            title={title}
            setTitle={setTitle}
            titleChapter={titleChapter}
            setTitleChapter={setTitleChapter}
            date={date}
            setDate={setDate}
            authors={authors}
            setAuthors={setAuthors}
            addAuthor={addAuthor}
            investigators={investigators}
            journal={journal}
            setJournal={setJournal}
            authorsId={authorsId}
            removeAuthor={removeAuthor}
            volPag={volPag}
            setVolPag={setVolPag}
            isbn={isbn}
            setIsbn={setIsbn}
            location={location}
            setLocation={setLocation}
            setCoordinators={setCoordinators}
            coordinators={coordinators}
            directors={directors}
            setDirectors={setDirectors}
            url={url}
            setUrl={setUrl}
            editors={editors}
            setEditors={setEditors}
          />
        )}
        {publicationType === publication.book && (
          <BookForm
            isIpUser={isIpUser}
            isCreate={isCreate}
            isAdmin={isAdmin}
            title={title}
            setTitle={setTitle}
            url={url}
            setUrl={setUrl}
            date={date}
            setDate={setDate}
            authors={authors}
            setAuthors={setAuthors}
            addAuthor={addAuthor}
            investigators={investigators}
            journal={journal}
            setJournal={setJournal}
            authorsId={authorsId}
            removeAuthor={removeAuthor}
            volPag={volPag}
            setVolPag={setVolPag}
            isbn={isbn}
            setIsbn={setIsbn}
            location={location}
            setLocation={setLocation}
            setCoordinators={setCoordinators}
            coordinators={coordinators}
            directors={directors}
            setDirectors={setDirectors}
            editors={editors}
            setEditors={setEditors}
          />
        )}
      </div>
    </div>
  );
};
