/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { FileUploader } from "../../../components/FileUploader/FileUploader";
import { IInvestigator } from "../../../interfaces/IInvestigator";
import { paths } from "../../../utils/paths";
import Axios from "axios";
import {
  create,
  Delete,
  getAll,
  getById,
  getByInvestigator,
  resetPass,
  saveFile,
  update,
} from "../../../utils/requests/dynamicReq";
import { validateEmail } from "../../../utils/validate";
import { ROUTES } from "../../../utils/routes";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IPublication } from "../../../interfaces/IPublication";
import estrella from "../../../assets/images/estrella.svg";
import estrellaAzul from "../../../assets/images/estrellaSolid.svg";
import { requiredInputs, SwalError } from "../../../utils/constants";
import moment from "moment";
import parse from "html-react-parser";

interface IGroupShort {
  group_id: string;
  name_es: string;
}

interface IInvestigatorRole {
  investigator_role_id: string;
  name: string;
}

interface IUserRole {
  user_role_id: string;
  name: string;
}

interface INewPassword {
  oldPassword: string;
  newPassword: string;
}

interface INewUser {
  username: string;
  ownerId: number;
  userRoleId: number;
  userType: string;
}

export const ManageProfile: FC = () => {
  let { token, investigatorId, isAdmin } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const history = useHistory();

  const { pathname }: any = useLocation();
  let id: any = "";
  if (pathname.includes("perfil")) {
    id = investigatorId?.toString()!;
  } else {
    id = pathname.split("/")[2];
  }
  let userType: string = "";
  if (pathname.includes("service")) {
    userType = "service-staff";
  } else {
    userType = "investigator";
  }

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const {
    INVESTIGATORS,
    GROUPS,
    INVESTROLE,
    USERROLE,
    USERS,
    newUsers,
    userSelectPUBLICATION,
    publicationsINVESTIGATOR,
    NEWPASSWORD,
    RESETPASSWORD,
  } = paths;

  const [isCreate, setIsCreate] = useState(false);
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //userForm
  const [userToEditId, setUserToEditId] = useState("");
  const [name, setName] = useState("");
  const [surname1, setSurname1] = useState("");
  const [surname2, setSurname2] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState();
  const [extension, setExtension] = useState("");
  const [phone, setPhone] = useState("");
  const [groups, setGroups] = useState<IGroupShort[]>([]);
  const [chosenGroupId, setChosenGroupId] = useState("");
  const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
  const [chosenUserRoleId, setChosenUserRoleId] = useState("");
  const [investigatorTypes, setInvestigatorTypes] = useState<IInvestigatorRole[]>(
    []
  );
  const [chosenUserTypeId, setChosenUserTypeId] = useState("");
  const [categoryEs, setCategoryEs] = useState("");
  const [categoryEn, setCategoryEn] = useState("");
  const [centre, setCentre] = useState("");
  const [publonsId, setPublonsId] = useState("");
  const [googleScholar, setGoogleScholar] = useState("");
  const [homepageUrl, setHomepageUrl] = useState("");
  const [orcid, setOrcid] = useState("");
  const [researchGate, setResearchGate] = useState("");
  const [academia, setAcademia] = useState("");
  const [dialnet, setDialnet] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [twitter, setTwitter] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [tagsEs, setTagsEs] = useState("");
  const [tagsEn, setTagsEn] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  // const [publications, setPublications] = useState<IPublication[]>([]);
  const [selectedPublications, setSelectedPublications] = useState<IPublication[]>(
    []
  );
  const [displayPublications, setDisplayPublications] = useState<IPublication[]>([]);

  //change Password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPass, setRepeatNewPass] = useState("");
  const [isPassChecked, setIsPassChecked] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [passMessage, setPassMessage] = useState("");

  useEffect(() => {
    isMountedRef.current = true;
    checkIfCreate();
    loadInvestigationGroups();
    loadInvestigationRoles();
    loadUserRole();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const checkIfCreate = () => {
    const { pathname }: any = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
    } else {
      loadInvestigator();
      if (pathname.includes("perfil")) {
        setIsLoading(true);
        setIsMyProfile(true);
        loadUserPublications();
      }
    }
  };

  const loadInvestigationGroups = async () => {
    try {
      let rawInvGroups = await getAll(GROUPS, source);

      if (isMountedRef.current) {
        filterGruops(rawInvGroups);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterGruops = (rawGroups: any[]) => {
    let filteredGropus: IGroupShort[] = rawGroups.map(({ id, nameEs }) => ({
      group_id: id,
      name_es: nameEs,
    }));
    setGroups(filteredGropus);
  };

  const loadInvestigationRoles = async () => {
    try {
      let roles = await getAll(INVESTROLE, source);

      if (isMountedRef.current) {
        filterInvestigatorRole(roles);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterInvestigatorRole = (roles: any[]) => {
    let filteredInvestigatorRoles: IInvestigatorRole[] = roles.map(
      ({ id, name }) => ({
        investigator_role_id: id,
        name,
      })
    );
    setInvestigatorTypes(filteredInvestigatorRoles);
  };

  const loadUserPublications = async () => {
    let publications = await getByInvestigator(
      publicationsINVESTIGATOR,
      investigatorId,
      source
    );
    // setPublications(publications);
    reorderPublications(publications);
    console.log(publications);
    setIsLoading(false);
  };

  const chechEmail = (value: string) => {
    let validatedEmail = validateEmail(value);
    if (!validatedEmail) {
      setMessage("Formato de email no válido.");
    } else {
      setMessage("");
    }
  };

  //----------------

  //HANDLE PUBLICATIONS

  const reorderPublications = (publications: any[]) => {
    console.log(publications);
    let newPublications: any[] = [];
    publications.map(
      ({
        id,
        title,
        date,
        journal,
        url,
        authors,
        volPag,
        investigatorId,
        investigatorPublications,
      }: // eslint-disable-next-line array-callback-return
      any) => {
        newPublications.push({
          id,
          title,
          date,
          journal,
          url,
          authors,
          volPag,
          investigatorId,
          selected: investigatorPublications[0]?.selected,
        });
      }
    );
    return filterSelectedPublications(newPublications);
  };

  const filterSelectedPublications = (publications: IPublication[]) => {
    console.log(publications);
    const selectedPublications = publications.filter(
      ({ selected }: IPublication) => selected === true
    );
    console.log(selectedPublications);
    setSelectedPublications(selectedPublications);
    const displayPublications = publications.filter(
      ({ selected }: IPublication) => selected === false
    );
    setDisplayPublications(displayPublications);
  };

  //----------------
  const createOREditInvestigatorForm = () => {
    let form: IInvestigator = isCreate
      ? {
          name,
          surname1,
          surname2,
          email,
          extension: +extension ? +extension : null,
          phone,
          centre,
          publonsId,
          googleScholar,
          homepageUrl,
          orcid,
          categoryEn,
          categoryEs,
          descriptionEs,
          descriptionEn,
          tagsEs,
          tagsEn,
          investigationGroupId: +chosenGroupId! ? +chosenGroupId! : null,
          investigatorRoleId: +chosenUserTypeId!,
          researchGate,
          academia,
          dialnet,
          linkedin: linkedIn,
          twitter,
          username: userName,
          password,
          userRoleId: +chosenUserRoleId,
        }
      : {
          name,
          surname1,
          surname2,
          email,
          extension: +extension ? +extension : null,
          phone,
          centre,
          publonsId,
          googleScholar,
          homepageUrl,
          orcid,
          categoryEn,
          categoryEs,
          descriptionEs,
          descriptionEn,
          tagsEs,
          tagsEn,
          investigationGroupId: +chosenGroupId! ? +chosenGroupId! : null,
          investigatorRoleId: +chosenUserTypeId!,
          researchGate,
          academia,
          dialnet,
          linkedin: linkedIn,
          twitter,
        };

    return form;
  };

  const editUserForm = (ownerId: number) => {
    let userForm: INewUser = {
      username: userName,
      ownerId,
      userType,
      userRoleId: +chosenUserRoleId,
    };
    return userForm;
  };

  const handleSubmit = () => {
    let question: string = "";
    isCreate
      ? (question = `¿Quieres crear un nuevo usuario?`)
      : (question = " `¿Quieres actualizar tu perfil?`");
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "buttonSwalConfirm",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed && isCreate) {
        handleNewUser();
      } else if (isConfirmed && !isCreate) {
        handleConfirmation();
      }
    });
  };

  const loadUserRole = async () => {
    try {
      let roles = await getAll(USERROLE, source);

      if (isMountedRef.current) {
        filterUserRole(roles);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterUserRole = (roles: any[]) => {
    let filteredUserRoles: IUserRole[] = roles.map(({ id, name }) => ({
      user_role_id: id,
      name,
    }));
    setUserRoles(filteredUserRoles);
  };

  const checkPassword = (value: string) => {
    let repeatPassword = value;
    if (password !== repeatPassword) {
      setMessage("Las contraseñas no coinciden.");
    } else {
      setMessage("");
    }
  };

  const handleNewUser = () => {
    if (
      !name ||
      !surname1 ||
      !categoryEs ||
      !email ||
      !userName ||
      chosenGroupId === "none" ||
      !chosenUserTypeId
    ) {
      Swal.fire({
        icon: "error",
        text: "Los campos con * son obligatorios. Por favor, completa el formulario.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      let form = createOREditInvestigatorForm();
      console.log(form);
      create(newUsers, form, source)
        .then(async ({ id }) => {
          console.log(id);
          Swal.fire({
            icon: "success",
            text: `Usuario creado correctamente.`,
          });
          if (typeof image !== "string") {
            const formData = new FormData();
            formData.append("image", image!);
            await saveFile(INVESTIGATORS, id, "image", formData, source);
          }
          history.push(`${ROUTES.Users.all}`);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "Ha habido un error, por favor intente de nuevo más tarde. Compruebe si el email o el usuario ya existe",
          });
        });
    }
  };

  // EDIT MY PROFILE
  const loadInvestigator = async () => {
    try {
      const {
        name,
        surname1,
        surname2,
        email,
        extension,
        phone,
        centre,
        publonsId,
        googleScholar,
        homepageUrl,
        orcid,
        categoryEn,
        categoryEs,
        descriptionEs,
        descriptionEn,
        tagsEs,
        tagsEn,
        investigationGroup,
        investigatorRole,
        researchGate,
        academia,
        dialnet,
        linkedin,
        twitter,
        image,
        user,
      } = await getById(INVESTIGATORS, id, source);
      let a = await getById(INVESTIGATORS, id, source);
      console.log(a);
      if (isMountedRef.current) {
        setName(name);
        setSurname1(surname1);
        setSurname2(surname2);
        setEmail(email);
        setExtension(extension);
        setPhone(phone);
        setCategoryEs(categoryEs);
        setCategoryEn(categoryEn);
        setCentre(centre);
        setPublonsId(publonsId);
        setOrcid(orcid);
        setGoogleScholar(googleScholar);
        setHomepageUrl(homepageUrl);
        setDescriptionEs(descriptionEs);
        setDescriptionEn(descriptionEn);
        setTagsEs(tagsEs);
        setTagsEn(tagsEn);
        investigationGroup?.id !== "NULL"
          ? setChosenGroupId(investigationGroup?.id)
          : setChosenGroupId("");
        setChosenUserTypeId(investigatorRole?.id);
        setResearchGate(researchGate);
        setAcademia(academia);
        setDialnet(dialnet);
        setLinkedIn(linkedin);
        setTwitter(twitter);
        setImage(image);
        setUserName(user?.username);
        setUserToEditId(user?.id);
        setChosenUserRoleId(user?.userRole?.id);
      }
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const handleConfirmation = async () => {
    if (
      !name ||
      !surname1 ||
      !categoryEs ||
      !email ||
      !userName ||
      chosenGroupId === "none" ||
      !chosenUserTypeId
    ) {
      Swal.fire({
        icon: "error",
        text: "Los campos con * son obligatorios. Por favor, completa el formulario.",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      let form = createOREditInvestigatorForm();
      console.log(form);
      update(INVESTIGATORS, id, form, token).then(() => {
        let userForm = editUserForm(+id);
        console.log(userForm);
        update(USERS, +userToEditId, userForm, token)
          .then(async () => {
            Swal.fire({
              icon: "success",
              text: `Perfil actualizado correctamente.`,
            });
            if (typeof image !== "string") {
              const formData = new FormData();
              formData.append("image", image!);
              await saveFile(INVESTIGATORS, id, "image", formData, source);
            }
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo más tarde..",
            });
          });
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres borrar este usuario?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        Delete(INVESTIGATORS, id, source)
          .then(({ id }) => {
            Swal.fire({
              icon: "success",
              text: `Usuario borrado correctamente.`,
            });
            history.push(ROUTES.Users.all);
            setIsCreate(false);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo más tarde.",
            });
          });
    });
  };

  const editDeleteButtons = () => {
    if (!isCreate && !isMyProfile) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Actualizar Perfil
          </button>
          <button
            className="smallBtn btn-success ml-1"
            onClick={handleResetPassword}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
          <button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    } else if (!isCreate && isMyProfile) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Actualizar Perfil
          </button>
        </>
      );
    } else {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Usuario
        </button>
      );
    }
  };

  //----------------

  //PUBLICATIONS

  const searchBar = (value: any) => {
    setDisplayPublications(
      displayPublications.filter(({ title }: any) =>
        title.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setDisplayPublications(displayPublications);
  };

  const changeIcon = (isSelected: any) => {
    return !isSelected ? estrella : estrellaAzul;
  };

  const changeNotSelected = (index: number) => {
    if (displayPublications[index!].selected === false) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = true;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (displayPublications[index!].selected === true) {
      const newdisplayPublications = [...displayPublications];
      newdisplayPublications[index!].selected = false;
      setDisplayPublications(newdisplayPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  const changeSelected = (index: number) => {
    if (selectedPublications[index!].selected === false) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = true;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    } else if (selectedPublications[index!].selected === true) {
      const newSelectedPublications = [...selectedPublications];
      newSelectedPublications[index!].selected = false;
      setSelectedPublications(newSelectedPublications);
      setTimeout(() => {
        handleSubmitPublications();
      }, 200);
    }
  };

  //----------------
  //HANDLE SELECTED PUBLICATIONS

  const handleSubmitPublications = () => {
    const newPublications = buildSelectedPublications();
    console.log(newPublications);
    const count = countSelected(newPublications);
    if (count > 5) {
      Swal.fire({
        icon: "error",
        text: "Se pueden seleccionar un máximo de 5 publicaciones",
        customClass: {
          cancelButton: "buttonClass",
        },
      });
    } else {
      create(`${userSelectPUBLICATION}/${id}/publication/`, newPublications, source)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: `Publicaciones seleccionadas correctamente.`,
          });
          loadUserPublications();
          isMountedRef.current && setIsCreate(false);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: SwalError,
          });
        });
    }
  };

  const buildSelectedPublications = () => {
    let newSelectedPublications: any[] = [];
    let completePublications = [...selectedPublications, ...displayPublications];
    completePublications.map(({ id: publicationId, selected }: any) =>
      newSelectedPublications.push({
        investigatorId,
        publicationId,
        selected,
      })
    );
    return newSelectedPublications;
  };

  const countSelected = (totalPublications: any[]) => {
    const count = totalPublications.filter(
      ({ selected }) => selected === true
    ).length;
    return count;
  };

  //----------------
  //CHANGE PASSWORD

  const checkNewPassword = (value: string) => {
    let repeatNewPass = value;
    if (newPassword !== repeatNewPass) {
      setPassMessage("Las contraseñas no coinciden.");
      setIsPassChecked(false);
    } else {
      setPassMessage("");
      setIsPassChecked(true);
    }
  };

  //----------------
  //HANDLE CAHNGE PASSWORD

  const handleSubmitNewPassword = () => {
    if (!oldPassword || !newPassword || !repeatNewPass) {
      setPassMessage(requiredInputs);
      setTimeout(() => setPassMessage(""), 4000);
      return;
    }
    const newPasswordForm: INewPassword = {
      oldPassword,
      newPassword,
    };
    Swal.fire({
      icon: "question",
      text: `¿Quieres cambiar tu contraseña?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleNewPasswordConfirmation(newPasswordForm);
    });
  };

  const handleNewPasswordConfirmation = (newPasswordForm: INewPassword) => {
    create(NEWPASSWORD, newPasswordForm, source)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Contraseña cambiada correctamente.`,
        });
        isMountedRef.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  //----------------
  //HANDLE RESET PASSWORD

  const handleResetPassword = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres resetear la contraseña de este usuario?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        resetPass(RESETPASSWORD, +userToEditId, source)
          .then(() => {
            const year = moment(new Date()).locale("es").format("YYYY");
            Swal.fire({
              icon: "success",
              text: `Contraseña reseteada a Ibyda${year}`,
            });
            isMountedRef.current && setIsCreate(false);
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: SwalError,
            });
          });
    });
  };

  return (
    <div className="centerPage">
      <div className="container-fluid ">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Perfil</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <div className="form-row mt-2  d-flex flex-start">
          {!isCreate ? (
            <div style={{ width: "50%", paddingLeft: "10%" }}>
              <FileUploader handleFile={setImage} image={image} />
            </div>
          ) : (
            <FileUploader handleFile={setImage} image={image} />
          )}

          <div className="col-6   justify-content-center">
            <small id="label">Nombre*</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Nombre"
              value={name}
              onChange={({ target: { value } }) => {
                setName(value);
              }}
            />
            <div className=" mt-2">
              {" "}
              <small>Primer apellido*</small>
              <input
                type="text"
                className="form-control"
                // placeholder="Primer Apellido"
                value={surname1}
                onChange={({ target: { value } }) => {
                  setSurname1(value);
                }}
              />
            </div>
            <div className=" mt-2">
              <small>Segundo apellido</small>
              <input
                type="text"
                className="form-control"
                // placeholder="Segundo Apellido"
                value={surname2!}
                onChange={({ target: { value } }) => {
                  setSurname2(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-row mt-3">
          <h5>Datos de usuario</h5>
        </div>

        <div className="form-row mt-2">
          <div className="col">
            <small>Nombre de Usuario*</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Nombre de usuario"
              value={userName}
              onChange={({ target: { value } }) => {
                setUserName(value);
              }}
            />
          </div>
          <div className="col">
            <small>Email*</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Email"
              value={email}
              onChange={({ target: { value } }) => {
                setEmail(value);
                chechEmail(value);
              }}
            />
          </div>
          <div className="col">
            <small>Teléfono</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Teléfono"
              value={phone ? phone : ""}
              onChange={({ target: { value } }) => {
                setPhone(value);
              }}
            />
          </div>
          <div className="col">
            <small>Extensión</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Extensión"
              value={extension ? extension : ""}
              onChange={({ target: { value } }) => {
                setExtension(value);
              }}
            />
          </div>
        </div>

        {isCreate && (
          <div className="form-row">
            <div className="col">
              <small id="label">Contraseña*</small>
              <input
                type="password"
                className="form-control"
                // placeholder="Contraseña"
                disabled={!isCreate}
                value={password}
                onChange={({ target: { value } }) => {
                  setPassword(value);
                }}
              />
            </div>
            <div className="col">
              <small>Repetir contraseña*</small>
              <input
                type="password"
                className="form-control"
                // placeholder="Repetir contraseña"
                disabled={!isCreate}
                value={repeatPassword}
                onChange={({ target: { value } }) => {
                  setRepeatPassword(value);
                  checkPassword(value);
                }}
              />
            </div>
          </div>
        )}

        {(isCreate || isAdmin) && (
          <div className="form-row mt-3">
            <div className="col">
              <small>Grupo*</small>
              <select
                className="form-control input"
                value={chosenGroupId!}
                onChange={({ target: { value } }) => {
                  setChosenGroupId(value);
                }}
              >
                <option value="none">Grupo</option>
                <option value="">No Procede</option>
                {groups?.map(({ group_id, name_es }) => (
                  <option key={group_id} value={group_id}>
                    {name_es}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <small>Tipo de investigador*</small>
              <select
                className="form-control input"
                value={chosenUserTypeId}
                onChange={({ target: { value } }) => {
                  setChosenUserTypeId(value);
                }}
              >
                <option>Tipo de Investigador</option>
                {investigatorTypes?.map(({ investigator_role_id, name }) => (
                  <option key={investigator_role_id} value={investigator_role_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <small>Rol de usuario*</small>
              <select
                className="form-control input"
                value={chosenUserRoleId}
                onChange={({ target: { value } }) => {
                  setChosenUserRoleId(value);
                }}
              >
                <option>Rol de usuario</option>
                {userRoles?.map(({ user_role_id, name }) => (
                  <option key={user_role_id} value={user_role_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="form-row mt-3">
          <h5>Enlaces personales</h5>
        </div>

        <div className="form-row mt-2">
          <div className="col">
            <small>Categoría Español*</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Categoría Español"
              value={categoryEs!}
              onChange={({ target: { value } }) => {
                setCategoryEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>Categoría Inglés</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Categoría Inglés"
              value={categoryEn ? categoryEn : ""}
              onChange={({ target: { value } }) => {
                setCategoryEn(value);
              }}
            />
          </div>
          <div className="col">
            <small>Institución</small>
            <input
              type="text"
              className="form-control"
              // placeholder="Institución"
              value={centre!}
              onChange={({ target: { value } }) => {
                setCentre(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col">
            <small>Publons Id</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://publons.com/researcher/1381263/juan-carlos-tojar-hurtado/"
              value={publonsId ? publonsId : ""}
              onChange={({ target: { value } }) => {
                setPublonsId(value);
              }}
            />
          </div>
          <div className="col">
            <small>Google Scholar</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://scholar.google.es/citations?user=zY3fTZMAAAAJ&hl=es&oi=ao"
              value={googleScholar ? googleScholar : ""}
              onChange={({ target: { value } }) => {
                setGoogleScholar(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Enlace a página personal</small>
            <input
              type="text"
              className="form-control"
              placeholder="Enlace a página personal Ejemplo: https://www.mipaginapersonal.es"
              value={homepageUrl ? homepageUrl : ""}
              onChange={({ target: { value } }) => {
                setHomepageUrl(value);
              }}
            />
          </div>
          <div className="col">
            <small>Orcid</small>
            <input
              className="form-control"
              value={orcid ? orcid : ""}
              placeholder="Ej:https://orcid.org/0000-0002-4009-0149"
              onChange={({ target: { value } }) => {
                setOrcid(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col">
            <small>ResearchGate</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://www.researchgate.net/profile/Juan-Carlos_Tojar"
              value={researchGate ? researchGate : ""}
              onChange={({ target: { value } }) => {
                setResearchGate(value);
              }}
            />
          </div>
          <div className="col">
            <small>Academia</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://uma.academia.edu/JuanCarlosT%C3%B3jarHurtado"
              value={academia ? academia : ""}
              onChange={({ target: { value } }) => {
                setAcademia(value);
              }}
            />
          </div>
          <div className="col">
            <small>Dialnet</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://dialnet.unirioja.es/servlet/autor?codigo=89115"
              value={dialnet ? dialnet : ""}
              onChange={({ target: { value } }) => {
                setDialnet(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col">
            <small>LinkedIn</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
              value={linkedIn ? linkedIn : ""}
              onChange={({ target: { value } }) => {
                setLinkedIn(value);
              }}
            />
          </div>
          <div className="col">
            <small>Twitter</small>
            <input
              type="text"
              className="form-control"
              placeholder="Ej:https://twitter.com/sfi_UMA"
              value={twitter ? twitter : ""}
              onChange={({ target: { value } }) => {
                setTwitter(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-3">
          <h5>Biografía</h5>
        </div>
        <div className="form-row mt-2">
          <div className="col">
            <small>Biografía Español</small>
            <textarea
              className="form-control textarea"
              // placeholder="Resumen Español"
              rows={3}
              value={descriptionEs ? descriptionEs : ""}
              onChange={({ target: { value } }) => {
                setDescriptionEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>Biografía Inglés</small>
            <textarea
              className="form-control textarea"
              // placeholder="Resumen Inglés"
              rows={3}
              value={descriptionEn ? descriptionEn : ""}
              onChange={({ target: { value } }) => {
                setDescriptionEn(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col">
            <small>Palabras clave español (separado por comas)</small>
            <textarea
              className="form-control textarea"
              // placeholder="Palabras clave español (separado por comas)"
              rows={3}
              value={tagsEs ? tagsEs : ""}
              onChange={({ target: { value } }) => {
                setTagsEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>Palabras clave inglés (separado por comas)</small>
            <textarea
              className="form-control textarea"
              // placeholder="Palabras clave inglés (separado por comas)"
              rows={3}
              value={tagsEn ? tagsEn : ""}
              onChange={({ target: { value } }) => {
                setTagsEn(value);
              }}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          {isMyProfile && (
            <div className="col">
              <button className="baseBtn" onClick={() => setIsModalOpen(true)}>
                Cambiar Contraseña
              </button>
            </div>
          )}
        </div>
      </div>

      {!isCreate && isMyProfile && (
        <div className="row mt-4">
          <div className="col-12 ">
            <div className="col d-flex justify-content-between">
              <h5>Publicaciones seleccionadas</h5>
            </div>
            <table className="table table-hover mt-4">
              <tbody>
                {selectedPublications?.map(
                  ({ id, title, authors, date, selected }: IPublication, index) => (
                    <tr key={index} className="pointer">
                      <td>
                        <div className="d-flex justify-content-between">
                          <h6>{parse(title)}</h6>
                          <img
                            style={{ width: "2%" }}
                            src={changeIcon(selected)}
                            alt=""
                            onClick={() => changeSelected(index)}
                          />
                        </div>

                        <p>
                          {authors} {","} {moment(date).locale("es").format("YYYY")}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && isMyProfile && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!selectedPublications.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay publicaciones seleccionadas-</h5>
              </div>
            )}
          </div>
        </div>
      )}
      {!isCreate && isMyProfile && (
        <div className="row mt-4">
          <Form.Group as={Col}>
            <InputGroup className="shadow">
              <InputGroup.Prepend>
                <InputGroup.Text className="inputGroup ">
                  <FontAwesomeIcon color="#0073B5" icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Buscar..."
                className="form-control"
                onChange={({ target: { value } }) => searchBar(value)}
              />
            </InputGroup>
          </Form.Group>
        </div>
      )}
      {!isCreate && isMyProfile && (
        <div className="row mt-3">
          <div className="col-12 ">
            <h5>Publicaciones</h5>
            <table className="table table-hover">
              <tbody>
                {displayPublications?.map(
                  ({ id, title, authors, date, selected }: IPublication, index) => (
                    <tr key={index} className="pointer">
                      <td>
                        <div className="d-flex justify-content-between">
                          <h6>{parse(title)}</h6>
                          <img
                            style={{ width: "2%" }}
                            src={changeIcon(selected)}
                            alt=""
                            onClick={() => changeNotSelected(index)}
                          />
                        </div>
                        <p>
                          {authors} {","} {moment(date).locale("es").format("YYYY")}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5" role="status" />
              </div>
            )}

            {!displayPublications.length && !isLoading && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay publicaciones-</h5>
              </div>
            )}
          </div>
        </div>
      )}

      {/* //Modal para cambiar contraseña */}
      <Modal centered show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Actualiza tu contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center text-danger">
                <span>{passMessage}</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  className="form-control"
                  type="password"
                  placeholder="*Contraseña Actual"
                  value={oldPassword}
                  onChange={({ target: { value } }) => {
                    setOldPassword(value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <input
                  className="form-control"
                  type="password"
                  placeholder="*Nueva Contraseña"
                  value={newPassword}
                  onChange={({ target: { value } }) => {
                    setNewPassword(value);
                  }}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <input
                  className="form-control"
                  type="password"
                  placeholder="*Repetir Contraseña"
                  value={repeatNewPass}
                  onChange={({ target: { value } }) => {
                    setRepeatNewPass(value);
                    checkNewPassword(value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Cerrar
          </Button>
          <Button variant="primary" disabled={!isPassChecked} onClick={handleSubmitNewPassword}>
            Guardar Cambio
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
