import React, { FC } from "react";

//middleware
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { Sidebar } from "../components/Sidebar/Sidebar";
import { ROUTES } from "../utils/routes";
import { Home } from "../views/Logged/Home/Home";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { userRoles } from "../utils/roles";
import Admin from "./admin";
import Investigator from "./investigator";
import Editor from "./editor";
import { Publications } from "../views/Logged/Publications/Publications";
import { ManagePublications } from "../views/Logged/Publications/ManagePublications/ManagePublications";
import { Groups } from "../views/Logged/Groups/Groups";
import { ManageGroups } from "../views/Logged/Groups/ManageGroups/ManageGroups";
import { Training } from "../views/Logged/Training/Training";
import { ManageTrainings } from "../views/Logged/Training/ManageTrainings/ManageTrainings";


const LoggedRoutes: FC = () => {
  //prettier-ignore
  let { token, userRoleId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  // const investigator = token && userRoleId === userRoles.investigator;
  // const invEditor = token && userRoleId === userRoles.invEditor;
  const editor =
    token && (userRoleId === userRoles.editor || userRoleId === userRoles.invEditor);

  return (
    <>
      <Sidebar />
      <Route exact path="/" component={Home} />
      <Route exact path={ROUTES.ManageProfile} component={ManageProfile} />
      <Route exact path={ROUTES.Publications.all} component={Publications} />
      <Route
        exact
        path={[ROUTES.Publications.create, ROUTES.Publications.update]}
        component={ManagePublications}
      />
      <Route exact path={ROUTES.Groups.all} component={Groups} />
      <Route
        exact
        path={[ROUTES.Groups.create, ROUTES.Groups.update]}
        component={ManageGroups}
      />
      <Route exact path={ROUTES.Training.all} component={Training} />
      <Route exact path={[ROUTES.Training.create, ROUTES.Training.update]} component={ManageTrainings} />
      {isAdmin && <Admin />}
      {!editor && <Investigator />}
      {(isAdmin || editor) && <Editor />}
    </>
  );
};

export default LoggedRoutes;
