import { IProject } from "../../../../interfaces/IProject";

export const validate = (p: IProject) => {
  if (p.nameEs.length > 499) return "El nombre no puede tener más de 500 caracteres";
  if (p.nameEn.length > 499) return "El nombre no puede tener más de 500 caracteres";
  if (p.code.length > 29) return "El código no puede tener más de 30 caracteres";
  if (p.link.length > 499) return "El link no puede tener más de 500 caracteres";
  if (p.funding.length > 499)
    return "El organismo financiador no puede tener más de 500 caracteres";
  if (p.budget.length > 499)
    return "El presupuesto financiador no puede tener más de 500 caracteres";

  if (!p.funding) return "Por favor rellene el organismo financiador";
  if (!p.code) return "Por favor rellene el código";

  if (!p.nameEs) return "Por favor rellene el nombre en Español.";

  if (
    !p.startDate ||
    p.startDate === "Invalid date" ||
    !p.endDate ||
    p.endDate === "Invalid date"
  ) {
    return "Por favor rellene las fechas";
  }
  if (p.endDate < p.startDate)
    return "La fecha de finalización no puede ser anterior a la de inicio.";
};
