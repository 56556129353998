//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { Users } from "../views/Logged/Users/Users";


const Admin = () => {
  // prettier-ignore
  return (
    <>
    <Route exact path={ROUTES.Users.all} component={Users} />
    <Route exact path={[ROUTES.Users.create, ROUTES.Users.update]} component={ManageProfile} />
    </>
  );
};

export default Admin;
