export const paths = {
  COMMUNICATIONS: "communications",
  INVESTIGATORS: "investigators",
  GROUPS: "investigation-groups",
  INVESTROLE: "investigator-roles",
  USERROLE: "user-roles",
  USERS: "users",
  COMMTYPES: "communication-types",
  PROJECTS: "projects",
  investigatorPROJECT: "investigator/project",
  SEMINARS: "",
  UNITS: "scientific-units",
  LINES: "research-lines",
  newUsers: "investigators/users",
  AGENDA: "agendas",
  AGENDATYPES: "agenda-types",
  AGENDADOCUMENTS: "agenda-documents/agenda",
  investigatorGROUP: "investigators/investigation-group",
  PUBLICATIONS:"publications",
  investigatorPUBLICATION: "investigator/publication",
  TRAININGS: "trainings",
  TRAININGTYPES: "training-types",
  groupPUBLICATION: "investigation-group/publication",
  publicationsByGROUP: "publications/investigation-group",
  publicationsINVESTIGATOR: "publications/investigator",
  NEWPASSWORD: "auth/change-password",
  groupSelectPUBLICATION:"investigation-group",
  userSelectPUBLICATION:"investigator",
  RESETPASSWORD:"auth/reset-password/user",
};
