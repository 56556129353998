import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//middelware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import Axios from "axios";
import moment from "moment";

//ckeditor
import CKEditor from "ckeditor4-react";
import { editorConfig, SwalError } from "../../../../utils/constants";

import {
  create,
  getAll,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import Swal from "sweetalert2";
import { paths } from "../../../../utils/paths";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { validate } from "./validations";

export const ManageNews = () => {
  const { COMMUNICATIONS, COMMTYPES } = paths;
  const { id } = useParams();
  const { deleteIt } = useDelete();

  let { investigatorId, isAdmin, userId } = useSelector(({ user }) => user);

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [titleEs, setTitleEs] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [contentEs, setContentEs] = useState("");
  const [contentEn, setContentEn] = useState("");
  const [newsTypes, setNewsTypes] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [communicationTypeId, setCommunicationTypeId] = useState("");
  const [creatorId, setCreatorId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [image, setImage] = useState();
  const [message, setMessage] = useState("");

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadData();
    loadStaticData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStaticData = async () => {
    try {
      let response = await getAll(COMMTYPES, source);
      isMountedRef.current && setNewsTypes(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadData = async () => {
    try {
      let response = await getById(COMMUNICATIONS, id, source);
      console.log(response);
      isMountedRef.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  // prettier-ignore
  const buildObjToLoad = (response) => {
    console.log(response);
    const { titleEn, titleEs, contentEn, contentEs, youtubeLink, image, keywords, date } = response;
    const {user: { id: userId }} = response;
    const {communicationType: { id: typeId },} = response;
    setCommunicationTypeId(typeId);
    setTitleEn(titleEn);
    setTitleEs(titleEs);
    setYoutubeLink(youtubeLink);
    setCreatorId(userId);
    setImage(image);
    setKeywords(keywords);
    setDate(new Date(date));

    setTimeout(() => {
      setContentEs(contentEs);
      setContentEn(contentEn);
    }, 100);
  };

  const handleSubmit = () => {
    const news = build();
    console.log(news);
    const notValid = validate(news);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} notica?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(news);
    });
  };

  const handleConfirmation = (news) => {
    let createOrUpdate = isCreate
      ? create(COMMUNICATIONS, news, source)
      : update(COMMUNICATIONS, id, news, source);
    createOrUpdate
      .then(async ({ id }) => {
        Swal.fire({
          icon: "success",
          text: `Noticia ${isCreate ? "creada" : "editada"} correctamente.`,
        });
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(COMMUNICATIONS, id, "image", formData, source);
        }
        history.push(`${ROUTES.News.all}/${id}`);
        setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = () => ({
    userId: creatorId? creatorId:userId,
    titleEs,
    titleEn,
    date: moment(date).format("YYYY-MM-DD"),
    youtubeLink,
    contentEs,
    contentEn,
    keywords,
    communicationTypeId: communicationTypeId ? communicationTypeId : null,
  });

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Noticia
        </button>
      );
    }
    if ((!isCreate && userId === creatorId) || isAdmin)  { 
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Actualizar Noticia
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(COMMUNICATIONS, id, ROUTES.News.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Noticia </h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-6 d-flex  justify-content-center">
            <FileUploader handleFile={setImage} image={image} />
          </div>

          <div className="col-6  d-flex flex-column  justify-content-center">
            <small>Enlace a video (Youtube)</small>
            <input
              type="text"
              className="form-control"
              placeholder="Enlace a video (Youtube)"
              value={youtubeLink}
              onChange={({ target: { value } }) => {
                setYoutubeLink(value);
              }}
            />
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col">
            <small>*Título español</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Título español"
              value={titleEs}
              onChange={({ target: { value } }) => {
                setTitleEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>Título inglés</small>
            <input
              type="text"
              className="form-control"
              placeholder="Título inglés"
              value={titleEn}
              onChange={({ target: { value } }) => {
                setTitleEn(value);
              }}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>*Tipo</small>
            <select
              className="form-control input"
              value={communicationTypeId}
              onChange={({ target: { value } }) => {
                setCommunicationTypeId(+value);
              }}
            >
              <option value={""}>*Tipo</option>
              {newsTypes?.map(({ id, nameEs }) => (
                <option key={id} value={+id}>
                  {nameEs}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <small>*Fecha de publicación</small>
            <DatePicker
              className="form-control input"
              placeholderText="*Fecha"
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>*Texto en español</small>
            <CKEditor
              data={contentEs}
              config={editorConfig}
              onChange={({ editor }) => {
                setContentEs(editor.getData());
              }}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>*Texto en inglés</small>
            <CKEditor
              data={contentEn}
              config={editorConfig}
              onChange={({ editor }) => {
                setContentEn(editor.getData());
              }}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Palabras clave (posicionamiento SEO)</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
              value={keywords}
              onChange={({ target: { value } }) => {
                setKeywords(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
