// prettier-ignore
export const validate = ({ titleEs, descriptionEs, startDate, endDate, place, agendaTypeId }: any) => {
  let aux = "";
  if (!titleEs) aux = "Por favor rellene el título en Español.";
  if (!descriptionEs) aux = "Por favor rellene el texto en Español.";
  if (!place) aux = "Por favor especifique el lugar.";
  if (!agendaTypeId) aux = "Por favor especifique el tipo de agenda.";
  if (
    !startDate ||
    startDate === "Invalid date" ||
    !endDate ||
    endDate === "Invalid date"
  ) {
    aux = "Por favor rellene las fechas";
  }
  if (endDate < startDate)
    aux = "La fecha de finalización no puede ser anterior a la de inicio.";
  return aux;
};
