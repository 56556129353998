//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { News } from "../views/Logged/News/News";
import { ManageNews } from "../views/Logged/News/ManageNews/ManageNews";
import { Agenda } from "../views/Logged/Agenda/Agenda";
import { ManageAgenda } from "../views/Logged/Agenda/ManageAgenda/ManageAgenda";

const Editor = () => {
  // prettier-ignore
  return (
    <>
   <Route exact path={ROUTES.News.all} component={News} />
   <Route exact path={[ROUTES.News.create, ROUTES.News.update]} component={ManageNews}/>
   <Route exact path={ROUTES.Agenda.all} component={Agenda} />
   <Route exact path={[ROUTES.Agenda.create, ROUTES.Agenda.update]} component={ManageAgenda}/>
    </>
  );
};

export default Editor;
