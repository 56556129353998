import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

//ckeditor
import CKEditor from "ckeditor4-react";
import { editorConfig, SwalError } from "../../../../utils/constants";
import { paths } from "../../../../utils/paths";
import { validate } from "./validations";
import Swal from "sweetalert2";
import {
  create,
  getAll,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import Axios from "axios";
import moment from "moment";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import { PdfUploader } from "../../../../components/FileUploader/PdfUploader";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { userRoles } from "../../../../utils/roles";

export const ManageAgenda = () => {
  const { AGENDA, AGENDATYPES } = paths;

  const { id } = useParams();

  const { userRoleId, isAdmin } = useSelector(({ user }) => user);
  const { editor, invEditor } = userRoles;
  const { deleteIt } = useDelete();

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);

  //userForm
  const [titleEs, setTitleEs] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [presenter, setPresenter] = useState("");
  const [agendaTypes, setAgendaTypes] = useState([]);
  const [agendaTypeId, setAgendaTypeId] = useState("");
  const [url, setUrl] = useState("");
  const [place, setPlace] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState();
  const [pdf, setPdf] = useState();

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadData();
    loadStaticData();
    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStaticData = async () => {
    try {
      let response = await getAll(AGENDATYPES, source);
      isMounted.current && setAgendaTypes(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadData = async () => {
    try {
      let response = await getById(AGENDA, id, source);
      console.log(response);
      isMounted.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };
  // prettier-ignore
  const buildObjToLoad = (response) => {
    const {
      titleEs,
      titleEn,
      descriptionEs,
      descriptionEn,
      startDate,
      endDate,
      presenter,
      url,
      place,
      active,
      image,
      pdf
    } = response;
    const {agendaType: { id: typeId }} = response;

    setTitleEs(titleEs);
    setTitleEn(titleEn);
    setTimeout(() => {
      setDescriptionEs(descriptionEs);
      setDescriptionEn(descriptionEn);
    }, 200);

    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    setPresenter(presenter);
    setUrl(url);
    setPlace(place);
    setAgendaTypeId(typeId);
    setIsActive(active)
    setImage(image)
    setPdf(pdf)
  };

  const handleSubmit = () => {
    const agenda = build();

    const notValid = validate(agenda);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} agenda?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(agenda);
    });
  };

  const handleConfirmation = (agenda) => {
    let createOrUpdate = isCreate
      ? create(AGENDA, agenda, source)
      : update(AGENDA, id, agenda, source);
    createOrUpdate
      .then(async ({ id: agendaId }) => {
        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Agenda ${createOrUpdateText} correctamente.`,
        });
        if (typeof image !== "string") {
          const formDataImg = new FormData();
          formDataImg.append("image", image);
          await saveFile(AGENDA, agendaId, "image", formDataImg, source);
        }
        if (typeof pdf !== "string") {
          const formDataPdf = new FormData();
          formDataPdf.append("pdf", pdf);
          //prettier-ignore
          const response = await saveFile(AGENDA, agendaId, "pdf", formDataPdf, source);
          setPdf(response.pdf);
        }
        history.push(`${ROUTES.Agenda.all}/${agendaId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = () => ({
    titleEs,
    titleEn,
    descriptionEs,
    descriptionEn,
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
    presenter,
    url,
    place,
    agendaTypeId,
  });

  const updateIsActive = () => {
    let text = !isActive ? "activar" : "desactivar";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${text} esta agenda?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        update(AGENDA, id, { active: !isActive }, source)
          .then(({ active }) => {
            isMounted.current && setIsActive(active);
            let text = active ? "activada" : "desactivada";
            Swal.fire({
              icon: "success",
              text: `Agenda ${text} correctamente.`,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: SwalError,
            });
          });
    });
  };

  //---------------

  //prettier-ignore
  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Agenda
        </button>
      );
    }
    if ((!isCreate && (userRoleId === editor || userRoleId === invEditor)) ||isAdmin) {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Actualizar Agenda
          </button>
          {isActive ? (
            <button
            className="smallBtn btn-success ml-1"
            onClick={() => updateIsActive()}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
          ) : (
            <button
            className="smallBtn btn-danger ml-1"
            onClick={() => updateIsActive()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          )}
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(AGENDA, id, ROUTES.Agenda.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };
  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Agenda</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-6 d-flex justify-content-center">
            <FileUploader handleFile={setImage} image={image} />
          </div>
          <div className="col-6  d-flex flex-column  justify-content-center">
            <PdfUploader handleFile={setPdf} pdf={pdf} />
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col">
            <small>*Nombre español</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre español"
              value={titleEs}
              onChange={({ target: { value } }) => setTitleEs(value)}
            />
          </div>
          <div className="col">
            <small>*Nombre inglés</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre inglés"
              value={titleEn}
              onChange={({ target: { value } }) => setTitleEn(value)}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Conferenciante</small>
            <input
              type="text"
              className="form-control"
              placeholder="Conferenciante"
              value={presenter}
              onChange={({ target: { value } }) => setPresenter(value)}
            />
          </div>
          <div className="col">
            <small>*Lugar</small>
            <input
              type="text"
              className="form-control"
              placeholder="Lugar"
              value={place}
              onChange={({ target: { value } }) => setPlace(value)}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <DatePicker
              className="form-control input"
              placeholderText="Inicio"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
            />
          </div>
          <div className="col">
            <DatePicker
              className="form-control input"
              placeholderText="Finalización"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
            />
          </div>
          <div className="col">
            <select
              className="form-control input"
              value={agendaTypeId}
              onChange={({ target: { value } }) => setAgendaTypeId(+value)}
            >
              <option value={""}>*Tipo</option>
              {agendaTypes?.map(({ id, name }) => (
                <option key={id} value={+id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>*Texto en español</small>
            <CKEditor
              data={descriptionEs}
              config={editorConfig}
              onChange={({ editor }) => setDescriptionEs(editor.getData())}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>*Texto en inglés</small>
            <CKEditor
              data={descriptionEn}
              config={editorConfig}
              onChange={({ editor }) => setDescriptionEn(editor.getData())}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
