import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//utils
import {
  create,
  getAll,
  Delete,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";

//ckeditor
import { SwalError, requiredInputs } from "../../../../utils/constants";

//datepicker
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

//middleware
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Axios from "axios";
import { ROUTES } from "../../../../utils/routes";

registerLocale("es", es);

export const ManageTrainings = () => {
  const { TRAININGS, TRAININGTYPES } = paths;
  const { id } = useParams();

  let { investigatorId, isAdmin, userId } = useSelector(({ user }) => user);

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);

  const [titleEs, setTitleEs] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [descriptionEs, setDescriptionEs] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  const [url, setUrl] = useState("");
  const [place, setPlace] = useState("");
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [trainingTypeId, setTrainingTypeId] = useState("");
  const [active, setActive] = useState(true);
  const [creatorId, setCreatorId] = useState(null);

  const [message, setMessage] = useState("");

  const isMounted = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMounted.current = true;
    const { pathname } = history.location;
    if (pathname.includes("crear")) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
      loadEditData();
    }
    loadDefaultData();
    return () => {
      isMounted.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDefaultData = async () => {
    let trainingTypes = await getAll(TRAININGTYPES, source);
    if (isMounted.current) {
      setTrainingTypes(trainingTypes);
    }
  };
  console.log(isCreate);
  const loadEditData = async () => {
    const {
      titleEs,
      titleEn,
      descriptionEs,
      descriptionEn,
      // startDate,
      // endDate,
      place,
      active,
      url,
      trainingType,
      user: { id: userId }
    } = await getById(TRAININGS, id, source);
    // let a = await getById(TRAININGS, id, source);
    // console.log(a);

    setTitleEs(titleEs);
    setTitleEn(titleEn);
    setUrl(url);
    setCreatorId(userId);
    // setStartDate(new Date(startDate));
    // setEndDate(new Date(endDate));
    setDescriptionEn(descriptionEn);
    setDescriptionEs(descriptionEs);
    setTrainingTypeId(trainingType?.id);
    setPlace(place);
    setActive(active ? 1 : 0);
  };

  // -----------------------

  const handleSubmit = () => {
    const training = build();
    console.log(training);
    if (!titleEs || !place || !trainingTypeId) {
      setMessage(requiredInputs);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} formación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(training);
    });
  };

  const handleConfirmation = (training) => {
    let createOrUpdate = isCreate
      ? create(TRAININGS, training, source)
      : update(TRAININGS, id, training, source);
    createOrUpdate
      .then(({ id: trainingId }) => {
        let createOrUpdateText = isCreate ? "creada" : "editada";
        Swal.fire({
          icon: "success",
          text: `Formación ${createOrUpdateText} correctamente.`,
        });
        history.push(`${ROUTES.Training.all}/${trainingId}`);
        isMounted.current && setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const build = () => ({
    userId: creatorId? creatorId:userId,
    titleEs,
    titleEn,
    descriptionEs,
    descriptionEn,
    // startDate: moment(startDate).format("YYYY-MM-DD"),
    // endDate: moment(endDate).format("YYYY-MM-DD"),
    place,
    active: active === 1 ? true : false,
    url,
    trainingTypeId,
  });

  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Formación
        </button>
      );
    }
    if ((!isCreate && userId === creatorId) || isAdmin)  {
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Formación
          </button>
          <button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "question",
      text: `¿Quieres borrar esta formación?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed &&
        Delete(TRAININGS, id, source)
          .then(({ id }) => {
            Swal.fire({
              icon: "success",
              text: `Formación borrada correctamente.`,
            });
            history.push(ROUTES.Training.all);
            setIsCreate(false);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              text: "Ha habido un error, por favor intente de nuevo más tarde.",
            });
          });
    });
  };

  return (
    <div className="centerPage">
      <div className="row">
        <div className="col d-flex justify-content-between">
          <div>
            <h3>Formación</h3>
          </div>
          <div>{editDeleteButtons()}</div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">
          <span>{message}</span>
        </div>
      </div>
      <div className="container-fluid mt-2">
        <div className="form-row mt-4">
          <div className="col">
            <small>*Nombre español</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre español"
              value={titleEs}
              onChange={({ target: { value } }) => setTitleEs(value)}
            />
          </div>
          <div className="col">
            <small>Nombre inglés</small>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre inglés"
              value={titleEn}
              onChange={({ target: { value } }) => setTitleEn(value)}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Link</small>
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              value={url}
              onChange={({ target: { value } }) => setUrl(value)}
            />
          </div>
          <div className="col">
            <small>Tipo</small>
            <select
              className="form-control input"
              value={trainingTypeId}
              onChange={({ target: { value } }) => setTrainingTypeId(+value)}
            >
              <option value={""}>*Tipo</option>
              {trainingTypes?.map(({ id, name }) => (
                <option key={id} value={+id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className="form-row mt-4">
          <div className="col">
            <small>Inicio</small>
            <DatePicker
              className="form-control input"
              placeholderText="Inicio"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
            />
          </div>
          <div className="col">
            <small>Finalización</small>
            <DatePicker
              className="form-control input"
              placeholderText="Finalización"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
            />
          </div>
        </div> */}
        <div className="form-row mt-4">
          <div className="col">
            <small>*Lugar</small>
            <input
              type="text"
              className="form-control"
              placeholder="Lugar"
              value={place}
              onChange={({ target: { value } }) => setPlace(value)}
            />
          </div>
          <div className="col">
            <small>*Activo</small>
            <select
              className="form-control input"
              value={active}
              onChange={({ target: { value } }) => setActive(value)}
            >
              <option value={1}>Sí</option>
              <option value={0}>No</option>
            </select>
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>Resumen en español. Máximo 300 caracteres</small>
            <textarea
              className="form-control textarea"
              maxLength={300}
              ng-trim={false}
              rows={6}
              value={descriptionEs}
              onChange={({ target: { value } }) => {
                setDescriptionEs(value);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-4">
          <div className="col">
            <small>Resumen en inglés. Máximo 300 caracteres</small>
            <textarea
              className="form-control textarea"
              rows={6}
              maxength={300}
              ng-trim={false}
              value={descriptionEn}
              onChange={({ target: { value } }) => {
                setDescriptionEn(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
