export const userRoles = {
  admin: 1,
  investigator: 2,
  editor: 3,
  invEditor: 4,
};

export const investigatorRoles = {
  ip: 1,
  consolidated: 2,
  senior: 3,
  junior: 4,
  technician: 5,
};
