//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProject } from "../views/Logged/Projects/ManageProjects/ManageProject";
import { Projects } from "../views/Logged/Projects/Projects";


const Investigator = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Projects.all} component={Projects} />
      <Route exact path={[ROUTES.Projects.create, ROUTES.Projects.update]} component={ManageProject}/>
    </>
  );
};

export default Investigator;
