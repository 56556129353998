//ckeditor
import CKEditor from "ckeditor4-react";
import { ckInput, editorConfigShort } from "./../../utils/constants";

//datepicker
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

export const ChapterForm = ({
  isIpUser,
  isCreate,
  isAdmin,
  title,
  setTitle,
  date,
  setDate,
  authors,
  setAuthors,
  addAuthor,
  investigators,
  journal,
  setJournal,
  authorsId,
  removeAuthor,
  volPag,
  setVolPag,
  titleChapter,
  setTitleChapter,
  coordinators,
  setCoordinators,
  directors,
  setDirectors,
  isbn,
  setIsbn,
  location,
  setLocation,
}) => {
  return (
    <>
      {(isCreate || titleChapter) && (
        <div className="form-row mt-3">
          <small>*Título del capítulo</small>
          <CKEditor
            className="form-control"
            style={ckInput}
            readOnly={!isIpUser && isCreate && !isAdmin}
            type="inline"
            data={titleChapter}
            config={editorConfigShort}
            onChange={({ editor }) => setTitleChapter(editor.getData())}
          />
        </div>
      )}
      <div className="form-row mt-3">
        <small>*Título del libro</small>
        <CKEditor
          className="form-control"
          style={ckInput}
          //ckEditor readOnly not working properly 
          // readOnly={!isIpUser && !isCreate && !isAdmin}
          type="inline"
          data={title}
          config={editorConfigShort}
          onChange={({ editor }) => setTitle(editor.getData())}
        />
      </div>

      <div className="form-row mt-3">
        <div className="col">
          <small>Páginas</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej: 593-601"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={volPag}
            onChange={({ target: { value } }) => {
              setVolPag(value);
            }}
          />
        </div>
        <div className="col">
          <small>*Fecha</small>
          <DatePicker
            className="form-control input mt-2"
            placeholderText="*Fecha"
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="dd MMMM, yyyy"
            disabled={!isIpUser && !isCreate}
            isClearable
            locale="es"
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            adjustDateOnChange
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>*Autores</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej: Navas-Castillo J, Rivera-Bustamante R, Roumagnac P"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={authors}
            onChange={({ target: { value } }) => {
              setAuthors(value);
            }}
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>*Seleccionar personal Ibyda</small>
          <select
            className="form-control input mt-2"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={""}
            onChange={({ target: { value } }) => {
              addAuthor(value);
            }}
          >
            <option value={""}>Selecionar autores ibyda</option>
            {investigators?.length &&
              investigators?.map((i, index) => (
                <option key={index} value={JSON.stringify(i)}>
                  {` ${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                </option>
              ))}
          </select>
          {!!authorsId[0]?.id &&
            authorsId?.map((ip, index) => (
              <span
                key={index}
                style={{ cursor: "pointer" }}
                className="badge badge-info mr-1 mt-3"
                onClick={() => removeAuthor(ip)}
              >
                {`${ip?.name} ${ip?.surname1} ${ip?.surname2 ? ip?.surname2 : ""}`} X
              </span>
            ))}
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>ISBN</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej:978-84-1377-216-5"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={isbn}
            onChange={({ target: { value } }) => {
              setIsbn(value);
            }}
          />
        </div>
        <div className="col">
          <small>Lugar</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej:Madrid"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={location}
            onChange={({ target: { value } }) => {
              setLocation(value);
            }}
          />
        </div>
        <div className="col">
          <small>*Editorial</small>
          <input
            className="form-control mt-2"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={journal}
            placeholder="Ej: Dykinson"
            onChange={({ target: { value } }) => {
              setJournal(value);
            }}
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>Coordinadores</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej: Navas-Castillo J, Rivera-Bustamante R, Roumagnac P"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={coordinators}
            onChange={({ target: { value } }) => {
              setCoordinators(value);
            }}
          />
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col">
          <small>Directores</small>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Ej: Navas-Castillo J, Rivera-Bustamante R, Roumagnac P"
            disabled={!isIpUser && !isCreate && !isAdmin}
            value={directors}
            onChange={({ target: { value } }) => {
              setDirectors(value);
            }}
          />
        </div>
      </div>
    </>
  );
};
